import React, { Suspense, lazy,useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

//componentes
const Header = lazy(()=>import('./components/header.js'));
const Footer = lazy(()=>import('./components/footer.js'));
const Loading = lazy(()=>import('./components/loading.js'));
const Redes = lazy(()=>import('./components/redes.js'));
//pages
const Home = lazy(() => import('./pages/home.js'));
const Software = lazy(() => import('./pages/software.js'));
const Features = lazy(() => import('./pages/features.js'));
const Casinos = lazy(() => import('./pages/casinos.js'));
const Poker = lazy(() => import('./pages/poker.js'));
const Pricing = lazy(() => import('./pages/pricing.js'));
const Form_New_Agent = lazy(() => import('./pages/form_new_aget'));

const Sports = lazy(() => import('./pages/sports.js'));
const Promotions = lazy(() => import('./pages/promotions.js'));
const Live_Demo = lazy(() => import('./pages/live_demo.js'));
const Contact = lazy(() => import('./pages/contact.js'));
const Why = lazy(() => import('./pages/why.js'));

function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
  }, []);
  return (
    <div className="App overflow-hidden">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Header/>
          <Redes/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="pre-match" element={<Software />} />
              <Route path="features" element={<Features />} />
              <Route path="casinos" element={<Casinos />} />
              <Route path="poker" element={<Poker />} />
              <Route path="sports" element={<Sports />} />
              <Route path="promotions" element={<Promotions />} />
              <Route path="live_demo" element={<Live_Demo />} />
              <Route path="start-today" element={<Contact />} />
              <Route path="why-american-per-head" element={<Why />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="form_new_agent" element={<Form_New_Agent />} />
            </Routes>
            <Footer/>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
